import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Form, Button, Alert, Card } from 'react-bootstrap';
import { Trans, useTranslation } from 'react-i18next';
import IsLoadingHOC from './IsLoadingHOC';
import { helpQueryAction } from '../redux/actions';
import { toast } from 'react-toastify';
import Captcha from './Captcha';
import { useSelector } from 'react-redux';
const Support = props => {
  const { t } = useTranslation();
  const [data, setData] = useState({
    name: '',
    email: '',
    description: '',
    captchaVal: '',
  });
  const config = useSelector(state => state.app.config);
  let [captchaValue, setCaptchaValue] = useState(false);
  let [resetCaptchaValue, setResetCaptchaValue] = useState(0);
  let [showAlert, setShowAlert] = useState(false);
  const [error, setError] = useState({ name: '', email: '', description: '', captchaVal: '' });
  // eslint-disable-next-line
  const validationError = { description: '', name: /^[A-Za-z]+$/, email: /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/, lastName: /^[A-Za-z]+$/, captchaVal: '' };
  let [captchaEnable, setCaptchaEnable] = useState(false);
  const [refreshReCaptcha, setRefreshReCaptcha] = useState({ state: null, token: null, requestBody: null });
  useEffect(() => {
    let errorArray = JSON.parse(JSON.stringify(error));
    if (captchaValue) {
      errorArray['captchaVal'] = '';
      let reqd = JSON.parse(JSON.stringify(data));
      reqd.captchaVal = captchaValue;
      setData(reqd);
    }
    setError(errorArray);
    // eslint-disable-next-line
  }, [captchaValue]);
  useEffect(() => {
    if (config.captchaConfig) {
      if (config.captchaConfig.supportCaptcha) {
        setCaptchaEnable(true);
      }
    }
  }, [config]);
  useEffect(() => {
    if (captchaEnable && refreshReCaptcha.state === 'v3' && refreshReCaptcha.token) {
      let requestBody = refreshReCaptcha.requestBody;
      requestBody['captchaVal'] = refreshReCaptcha.token;
      let reqd = JSON.parse(JSON.stringify(data));
      apiHandler(requestBody, reqd);
    }
  }, [refreshReCaptcha]);
  const handleChange = e => {
    let key = e.target.name;
    let val = e.target.value;
    let reqd = JSON.parse(JSON.stringify(data));
    let errd = JSON.parse(JSON.stringify(error));
    if (val.match(validationError[key]) || val === '' || key === 'email') {
      reqd[key] = val;
    }
    if (key === 'name') {
      if (!reqd[key] || reqd[key].trim().length < 1) {
        errd[key] = `Name is required`;
      } else {
        errd[key] = ``;
      }
    } else if (key === 'email') {
      if (!reqd[key].match(validationError[key])) {
        errd[key] = `Email is required`;
      } else {
        errd[key] = ``;
      }
    } else if (key === 'description') {
      if (!reqd[key] || val.trim().length < 1) {
        errd[key] = `Message is required`;
      } else {
        errd[key] = ``;
      }
    }
    setError(errd);
    setData(reqd);
    if (!captchaValue && captchaEnable && config.captchaConfig.recaptchaVersion === 'V3') {
      setRefreshReCaptcha({ ...refreshReCaptcha, state: Date.now() });
    }
  };
  const onSubmit = () => {
    let reqd = JSON.parse(JSON.stringify(data));
    let errd = JSON.parse(JSON.stringify(error));
    let isError = false;
    for (let key in reqd) {
      let val = reqd[key].trim();
      if (key === 'name') {
        if (!val || val.length < 1) {
          isError = true;
          errd[key] = `Name is required`;
        } else {
          errd[key] = ``;
        }
      } else if (key === 'email') {
        if (!val.match(validationError[key])) {
          errd[key] = `Email is required`;
          isError = true;
        } else {
          errd[key] = ``;
        }
      } else if (key === 'description') {
        if (!val || val.length < 1) {
          errd[key] = `Message is required`;
          isError = true;
        } else {
          errd[key] = ``;
        }
      } else if (key === 'captchaVal' && captchaEnable) {
        if (!val || val.length < 2) {
          errd[key] = `Please check Captcha`;
          isError = true;
        } else {
          errd[key] = ``;
        }
      }
    }
    setError(errd);
    if (!isError) {
      props.setLoading(true);
      let requestData = { email: reqd.email, name: reqd.name, message: reqd.description };
      if (captchaEnable) {
        requestData['captchaVal'] = reqd.captchaVal;
      }
      if (captchaEnable && config.captchaConfig && config.captchaConfig.recaptchaVersion === 'V3') {
        setRefreshReCaptcha({ ...refreshReCaptcha, state: 'init', requestBody: requestData });
        return;
      }
      apiHandler(requestData, reqd);
    }
  };
  const apiHandler = (requestData, reqd) => {
    helpQueryAction(requestData)
      .then(response => {
        for (let key in reqd) {
          reqd[key] = '';
        }
        if (config.captchaConfig && config.captchaConfig.recaptchaVersion === 'V3') {
          reqd.captchaVal = captchaValue;
        }
        setResetCaptchaValue(resetCaptchaValue + 1);
        setData(reqd);
        setShowAlert(true);
        props.setLoading(false);
      })
      .catch(error => {
        toast.error(t('Something went wrong'));
        props.setLoading(false);
      });
  };
  return (
    <Container className="justify-content-md-center section-content" id="support-content">
      <div className="register-page">
        <Card>
          <Form role="form">
            <h1 className="main-heading text-center">{t('Support')}</h1>
            <p className="text-center mb-5">If you have any inquiries or trouble registering, please message us below. Please note - this is not a chatbox.</p>
            {showAlert ? (
              <Row md={1}>
                <Col className="text-center mb-5">
                  <Alert variant="success">
                    <Alert.Heading>Thank you for query. </Alert.Heading>
                    <p>We will get back to you soon. Have a great day!</p>
                  </Alert>
                </Col>
              </Row>
            ) : (
              ''
            )}
            <Row md={2}>
              {/*[START:FIRSTNAME]*/}
              <Col className="form-group theme-signup-firstname mb-4">
                <label htmlFor="Name">Name</label>
                <Form.Control size="md" type="text" value={data.name} data-test="register-name" name="name" onChange={handleChange} onFocus={handleChange} id="Name" placeholder={`${t('Enter ')}${t('Name')}`} aria-required="true" aria-autocomplete="Name" />
                <span className="input-error" role="alert">
                  {error.name}
                </span>
              </Col>
              {/*[END:FIRSTNAME]*/}

              {/*[START:EMAIL]*/}
              <Col className="form-group theme-signup-email mb-4">
                <label htmlFor="email">Email</label>
                <Form.Control size="md" type="email" value={data.email} data-test="register-email" name="email" onChange={handleChange} onFocus={handleChange} id="email" placeholder={`${t('Enter ')}${t('lang_key_email')}`} aria-required="true" aria-autocomplete="email" />
                <span className="input-error" role="alert">
                  {error.email}
                </span>
              </Col>
              {/*[END:EMAIL]*/}
            </Row>
            <Row md={1}>
              <Col className="form-group theme-signup-email mb-4">
                <label htmlFor="description">Message/Description</label>
                <Form.Control size="md" as="textarea" value={data.description} placeholder={t('Leave a description here')} onChange={handleChange} name="description" id="description" style={{ height: '100px' }} onFocus={handleChange} />
                <span className="input-error" role="alert">
                  {error.description}
                </span>
              </Col>
            </Row>
            {captchaEnable ? (
              <Row md={4}>
                <Col className="mb-4">
                  {config.captchaConfig && config.captchaConfig.recaptchaVersion === 'V3' ? <Captcha reset={refreshReCaptcha.state} parentCallback={setCaptchaValue} v3Callback={setRefreshReCaptcha} /> : <Captcha reset={resetCaptchaValue} parentCallback={setCaptchaValue} />}
                  <span className="input-error" role="alert">
                    {error.captchaVal}
                  </span>
                </Col>
              </Row>
            ) : (
              ''
            )}
            <Row md={1}>
              <Col className="text-center">
                <Button variant="primary" className="btn btn-primary themeBackground" size="lg" id="register-submit-btn" data-test="register-submit" onClick={onSubmit}>
                  <Trans>Submit</Trans>
                </Button>
              </Col>
            </Row>
          </Form>
        </Card>
      </div>
    </Container>
  );
};

export default IsLoadingHOC(Support, 'Wait .....');
