import React from "react";
import { Container, Alert } from "react-bootstrap";
import { Trans } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
const ExpiredCampaign = (props) => {
	const config = useSelector((state) => state.app.config);
	const history = useHistory();
	React.useEffect(() => {
		if (config.campaignState !== "expired") {
			history.push("/");
		}
	}, [config]);
	return (
        <div className="d-flex align-items-center justify-content-center" 
		style={{height:'100vh',
		background: 'rgb(238,174,202)',
		background: 'radial-gradient(circle, rgba(238,174,202,1) 0%, rgba(237,240,245,1) 100%)'}}>
            <div className="text-center">
                <h1 className="display-1 fw-bold">404</h1>
                <p className="fs-3"> <span className="text-danger">Opps!</span> The promotion has been ended on</p>                
				<p className="text-center">{config.activationEnd ? new Date(config.activationEnd).toLocaleString() : ""}</p>
                {/* <a href="/" className="btn btn-primary">Go Home</a> */}
            </div>
        </div>
      )
	/* return (
		<Container>
			<Alert variant="danger" className="text-center" style={{ marginTop: "20%" }}>
				<Alert.Heading>
					<Trans>Sorry</Trans>!
				</Alert.Heading>
				<p className="text-center">
					<Trans>The promotion has been ended on</Trans>
				</p>
				<p className="text-center">{config.activationEnd ? new Date(config.activationEnd).toLocaleString() : ""}</p>
			</Alert>
		</Container>
	); */
};

export default ExpiredCampaign;
