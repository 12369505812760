import React, { useState, useRef } from 'react';
import { useParams, Redirect, Link } from 'react-router-dom';
import { pointsUpdate } from '../../redux/actions';
import { Container, Col, Row, ProgressBar } from 'react-bootstrap';
import YouTube from 'react-youtube';
import { Trans, useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
const WatchVideo = props => {
  const { t } = useTranslation();
  const videoRef = useRef(null);
  const activityData = props.activityData;
  const [pageRedirect, setPageRedirect] = useState(false);
  const [watchStatus, setWatchStatus] = useState({ status: false, playbackevtid: '' });
  const [progress, setProgress] = useState(0);
  const [isFullScreen, setIsFullscreen] = useState(null);
  const [customePlayerCurrentDuration, setCustomePlayerCurrentDuration] = useState(0);
  const [customePlayer, setCustomePlayer] = useState({ isPaused: true, duration: 0, volumnSeekBarPer: 1, isMute: false });
  let { id } = useParams();
  const youtube_parser = url => {
    // eslint-disable-next-line
    var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#\&\?]*).*/;
    var match = url.match(regExp);
    if (match && match[7].length === 11) {
      var b = match[7];
      return b;
    } else {
      return url;
    }
  };
  if (activityData['channel'] === 'youtube') {
    activityData['data'] = activityData['data'].indexOf('http') >= 0 ? youtube_parser(activityData['data']) : activityData['data'];
  }
  const vedioPlayerOpts = {
    height: '360',
    width: '100%',
    playerVars: {
      rel: 0,
      autoplay: 0,
      controls: 0,
      disablekb: 1,
      enablejsapi: 1,
      fs: 1,
      origin: window.location.origin,
      playsinline: 1,
      widgetid: 1,
    },
  };
  const playerOnReady = player => {
    let total = player.target.getDuration();
    let time = player.target.getCurrentTime();
    let playerTimeDifference = (time / total) * 100;
    setProgress(playerTimeDifference);
  };
  const playerOnStateChange = event => {
    if (event.data === 1) {
      let total = event.target.getDuration();
      setInterval(function() {
        let time = event.target.getCurrentTime();
        let playerTimeDifference = (time / total) * 100;
        setProgress(playerTimeDifference);
      });
    }
  };
  const videoOnplay = event => {
    if (!watchStatus.status) {
      setWatchStatus(Object.assign({}, watchStatus, { status: true }));
      let data = {
        reference: id,
        state: 'started',
      };

      props
        .pointsUpdate(data, 'post', 'view')
        .then(responseData => {
          if (responseData['data']) {
            setWatchStatus(Object.assign({}, watchStatus, { status: true, playbackevtid: responseData.data.id }));
          } else {
            setWatchStatus(Object.assign({}, watchStatus, { status: false, playbackevtid: 'none' }));
          }
        })
        .catch(err => {
          console.log('Response Error:', JSON.stringify(err));
          setWatchStatus(Object.assign({}, watchStatus, { status: false, playbackevtid: 'none' }));
        });
    }
  };
  const videoEnd = event => {
    let data = {
      id: watchStatus.playbackevtid,
      state: 'completed',
    };
    if (watchStatus.playbackevtid !== '') {
      props
        .pointsUpdate(data, 'put', 'view')
        .then(responseData => {
          if (responseData['data']) {
            toast.success(`${t('Congratulations')}! ${t('You have earned')} ${activityData.points} ${t('Points')}`);
          } else {
            toast.error(`${t('Sorry')}! ${t("You've earned the maximum points possible for this action")}`);
          }
          setPageRedirect(true);
        })
        .catch(err => {
          if (watchStatus.playbackevtid === 'none') {
            toast.error(`${t('Sorry')}! ${t("You've earned the maximum points possible for this action")}`);
          } else {
            toast.error(t('Something went wrong'));
          }
          console.log('Response Error:', JSON.stringify(err));
          setPageRedirect(true);
        });
    } else {
      setPageRedirect(true);
      toast.error(`${t('Sorry')}! ${t("You've earned the maximum points possible for this action")}`);
    }
  };
  const customePlayerAction = (type, e) => {
    if (!videoRef) {
      return false;
    }
    let d = { ...customePlayer };
    d.duration = videoRef.current.duration;
    if (type === 'play') {
      d.isPaused = false;
      videoRef.current.play();
    } else if (type === 'pause') {
      d.isPaused = true;
      videoRef.current.pause();
    } else if (type === 'mute') {
      d.isMute = !d.isMute;
      videoRef.current.muted = d.isMute;
    } else if (type === 'volumn') {
      d.volumnSeekBarPer = e.target.value;
      videoRef.current.volume = d.volumnSeekBarPer;
    } else if (type === 'fullscreen') {
      var el = window.document.getElementById('custom_video_player');
      if (!isFullScreen) {
        el.requestFullscreen();
      } else {
        el.exitFullscreen();
      }
    }
    console.log(videoRef);
    setCustomePlayer(d);
  };
  window.setInterval(function() {
    setCustomePlayerCurrentDuration(parseInt(videoRef.current?.currentTime));
    setProgress(parseInt((videoRef.current?.currentTime / videoRef.current?.duration) * 100));
  }, 1000);
  React.useEffect(() => {
    function onFullscreenChange() {
      setIsFullscreen(Boolean(document.fullscreenElement));
    }
    document.addEventListener('fullscreenchange', onFullscreenChange);
    return () => document.removeEventListener('fullscreenchange', onFullscreenChange);
  }, []);
  if (pageRedirect) {
    return <Redirect to="/" />;
  } else {
    return (
      <>
        <div className="portal-point-list-container">
          <Container fluid>
            <div className="label">
              <Link id="headerParentLink" className="parent-link" to="/earnpoints">
                <Trans>Earn Points</Trans>
              </Link>{' '}
              <b>{activityData['title'] ? activityData.title : ''}</b>
            </div>
          </Container>
          <Container fluid>
            <Row xs={1} md={2} lg={2}>
              {props.activityData['channel'] === 'youtube' ? (
                <Col>
                  <YouTube id="youtube-iframe" onEnd={videoEnd} onPlay={videoOnplay} videoId={activityData['data']} opts={vedioPlayerOpts} onReady={playerOnReady} onStateChange={playerOnStateChange} />
                  <ProgressBar variant="success" min={0} max={100} now={progress} />
                  <h5 className="earn-point-label uppercase alignCenter">
                    <Trans>WATCH AND EARN</Trans> {activityData['points'] ? activityData.points : 0} <Trans>Points</Trans>
                  </h5>
                </Col>
              ) : (
                <Col>
                  <video id="custom_video_player" width={'100%'} onClick={e => customePlayerAction(customePlayer.isPaused ? 'play' : 'pause')} preload="auto" onPlay={videoOnplay} onEnded={videoEnd} ref={videoRef} volume={customePlayer.volumnSeekBarPer}>
                    <source src={activityData['data']} />
                    <p>
                      Your browser doesn't support HTML5 video.
                      <a href={activityData['data']}>Download</a> the video instead.
                    </p>
                  </video>
                  <Container>
                    <Row className="video-controls pt-1">
                      <Col md={1} className="px-0">
                        <button type="button" className="btn btn-link" id="play-pause" onClick={e => customePlayerAction(customePlayer.isPaused ? 'play' : 'pause')}>
                          <span class="mat-icon material-icons">{customePlayer.isPaused ? 'play_arrow' : 'pause'}</span>
                        </button>
                      </Col>
                      <Col md={2} className="pt-2">
                        <span>
                          {Math.floor(customePlayerCurrentDuration / 60) < 10 ? `0${Math.floor(customePlayerCurrentDuration / 60)}` : Math.floor(customePlayerCurrentDuration / 60)}:{Math.floor(customePlayerCurrentDuration % 60) < 10 ? `0${Math.floor(customePlayerCurrentDuration % 60)}` : Math.floor(customePlayerCurrentDuration % 60)}
                        </span>
                      </Col>
                      <Col md={5} className="pt-3">
                        <ProgressBar variant="success" min={0} max={100} now={progress} style={{ height: '0.5rem' }} />
                      </Col>
                      <Col md={1}>
                        <button type="button" id="mute" className="btn btn-link" onClick={e => customePlayerAction('mute')}>
                          <span class="mat-icon material-icons">{customePlayer.isMute || customePlayer.volumnSeekBarPer < 0.05 ? 'volume_off' : customePlayer.volumnSeekBarPer > 0.6 ? 'volume_up' : customePlayer.volumnSeekBarPer > 0.1 ? 'volume_down' : 'volume_mute'}</span>
                        </button>
                      </Col>
                      <Col md={2} className="pt-2">
                        <input type="range" id="volume-bar" style={{ width: '100%' }} onClick={e => customePlayerAction('volumn', e)} min="0" max="1" step="0.1" />
                      </Col>
                      <Col md={1}>
                        <button type="button" id="full-screen" className="btn btn-link" onClick={e => customePlayerAction('fullscreen')}>
                          <span className="mat-icon material-icons">{isFullScreen ? 'fullscreen_exit' : 'fullscreen'}</span>
                        </button>
                      </Col>
                    </Row>
                  </Container>
                  <h5 className="earn-point-label uppercase alignCenter">
                    <Trans>WATCH AND EARN</Trans> {activityData['points'] ? activityData.points : 0} <Trans>Points</Trans>
                  </h5>
                </Col>
              )}
              <Col>
                <h5 className="h5title">
                  <Trans>Watch and Earn Points</Trans>
                </h5>
                <h4 className="title">
                  <b>{activityData['title'] ? activityData.title : ''}</b>
                </h4>
                <p className="description">{activityData['description'] ? activityData.description : ''}</p>
              </Col>
            </Row>
          </Container>
        </div>
      </>
    );
  }
};
export default connect(null, { pointsUpdate })(WatchVideo);
