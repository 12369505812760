import React from "react";
import PortalPointList from "../../components/PortalPointList";
import CarouselCustom from "../../components/CarouselCustom";
import Contests from "../../components/Contests";
class Home extends React.Component {
	render() {
		return (
			<>
				<CarouselCustom />
				<PortalPointList pagesize={4} />
				<Contests pagesize={4} />
			</>
		);
	}
}

export default Home;
