import React from 'react';
import { useSelector } from 'react-redux';
import GoogleCaptchaV2 from './captcha/GoogleCaptchaV2';
import GoogleCaptchaV3 from './captcha/GoogleCaptchaV3';
import { useTranslation } from 'react-i18next';

const Captcha = props => {
  const config = useSelector(state => state.app.config);
  const [lang, setLang] = React.useState('en');
  const { i18n } = useTranslation();
  React.useEffect(() => {
    setLang(i18n.language === 'fr' ? 'fr-CA' : 'en');
  }, [i18n.language]);

  if (config.captchaConfig.recaptchaVersion === 'V2') {
    return <GoogleCaptchaV2 {...props} lang={lang} />;
  } else if (config.captchaConfig.recaptchaVersion === 'V3') {
    return <GoogleCaptchaV3 {...props} lang={lang} />;
  } else {
    return <GoogleCaptchaV2 {...props} lang={lang} />;
  }
};

export default Captcha;
