import React, { Fragment } from "react";
import Header from "../layout/Header";
import Footer from "../layout/Footer";
import TierInformation from "../TierInformation";

export default ({ children }) => (
	<Fragment>
		<a  aria-label="Skip to main navigation" className="skiptocontent" href="#main">Skip To Content</a>
		<Header />
		<main id="main">
			{children}
		</main>
		<Footer />
		<TierInformation />
	</Fragment>
);
