import React, { useEffect, useState } from "react";
import Card from "./Card";
import { connect, useSelector } from "react-redux";
import { getContestAction } from "../redux/actions";
import { Button, Container, Row, Col } from "react-bootstrap";
import { Trans } from "react-i18next";
import IsLoadingHOC from "../components/IsLoadingHOC";
import { Redirect } from "react-router-dom";

const Contests = (props) => {
	const user = useSelector(({ user }) => user);
	const [data, setData] = useState([]);
	const [viewMore, setViewMore] = useState(true);
	const [page, setPage] = useState(0);
	const [pageRedirect, setPageRedirect] = useState(false);
	const pagesize = props.pagesize ? props.pagesize : 4;
	useEffect(() => {
		getNextData(0);
		// eslint-disable-next-line
	}, []);
	const getNextData = (page) => {
		props.setLoading(true);
		props
			.getContestAction(page + 1, pagesize)
			.then((response) => {
				let activityList = response.data;
				setData([...data, ...activityList]);
				setPage(page + 1);
				setViewMore(response.viewMore);
				props.setLoading(false);
			})
			.catch((ex) => {
				console.log("Error In API Call", ex);
				props.setLoading(false);
			});
	};
	const activityCLick = async (activityData, redirectUrl) => {
		try {
			setPageRedirect(redirectUrl);
		} catch (err) {
			console.log("Error in Activity status check", err);
			props.setLoading(false);
		}
	};
	if (pageRedirect) {
		return <Redirect push to={pageRedirect} />;
	} else {
		return (
			<Container fluid>
				<div className="post-login">
					<Container fluid>
						<h2>CONTESTS</h2>
					</Container>
					<Container fluid>
						<Row xs={1} md={2} lg={4} className="card_holder">
							{data.map((item, index) => {
								return (
									<Col key={index}>
										<Card>
											<div onClick={(e) => activityCLick(item, `/contest/${item.id}`)} 
											className={(user.pointBalance >= item.pointRequired ? " " : "activity-block-disable ") + "activity-block"}
											>
												<div className="activity-img-holder">
													<img src={item.cardHeaderImage} alt="Avatar" />
												</div>
												<div className="activity-info align-item-start">
													<div className="points">
														<Trans>Points</Trans>: {item.pointRequired}
													</div>
													<h5>
														{item.name}
													</h5>
												</div>
												<div className="row justify-content-center align-items-end">
													<Button disabled={user.pointBalance < item.pointRequired ? true : false} activity-type={item.actionType} id={`spin-enter-btn-${item.id}`} className="cart-button col-10">
														{user.pointBalance < item.pointRequired ? <Trans>Unlock With More Points</Trans> : (item.contestType && item.contestType === 'mysteryBox')?<Trans>Enter Now</Trans>:<Trans>Spin Now</Trans>}
													</Button>
												</div>
											</div>
										</Card>
									</Col>
								);
							})}
						</Row>
						<div className="text-center col my-4">
							{viewMore && (
								<div className="text-center w-100">
									{" "}
									<Button variant="secondary" id="view-more-earn-pointlist-btn" className="viewMore" onClick={() => getNextData(page)}>
										<Trans>View More</Trans>
									</Button>{" "}
								</div>
							)}
						</div>
					</Container>
				</div>
			</Container>
		);
	}
};

export default connect(null, { getContestAction })(IsLoadingHOC(Contests, "Loading...."));
