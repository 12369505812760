import React from "react";
import {getManu} from "../../redux/actions";
import {connect} from "react-redux";
import {footerMenu} from "../../data";
import {Container} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebook, faTwitter, faInstagramSquare } from "@fortawesome/free-brands-svg-icons";
import CopyRight from "../../components/CopyRight";
//import { useTranslation } from "react-i18next";
const  Footer = () =>  {
	//const { t } = useTranslation();
	return (
		<footer>
			<Container fluid className="footer">
				<div className="social-links">
					<ul className="footer-social">
						<li>
							<a href="route" >
								<FontAwesomeIcon icon={faFacebook} alt="connect on Facebook" />
							</a>
						</li>
						<li>
							<a href="route" >
								<FontAwesomeIcon icon={faInstagramSquare} alt="connect on Instagram" />
							</a>
						</li>
						<li>
							<a href="route" >
								<FontAwesomeIcon icon={faTwitter} alt="connect on Twitter" />
							</a>
						</li>
					</ul>
				</div>
				<div className="footer-link" aria-label="Footer Navigation">
					<ul>
						{
							footerMenu.map(item=> {
								return <li key={item.id}><a href={item.route}>{item.title}</a></li>;
							})
						}
					</ul>
				</div>
			</Container>
			<CopyRight/>
		</footer>
	);
	
	
};
export default connect(null, {getManu})(Footer);
