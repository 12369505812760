import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import LoadExternalScriptFile from "../hooks/LoadExternalScriptFile";
import ReactGA from "react-ga";
const GoogleAnalytics = (props) => {
	const location = useLocation();
	const campaignConfig = useSelector(({ app }) => app.config);

	useEffect(() => {
		if (campaignConfig.googleAnalyticsId) {
			ReactGA.initialize(campaignConfig.googleAnalyticsId);
			ReactGA.set({ page: window.location.href });
			ReactGA.pageview(window.location.pathname);
		}
	}, [campaignConfig, location]);
	function gtag() {
		window.dataLayer.push(arguments);
	}

	const loadAnalyticsId = () => {
		window.dataLayer = window.dataLayer || [];
		try {
			if (campaignConfig.googleAnalyticsId) {
				gtag("js", new Date());
				gtag("config", campaignConfig.googleAnalyticsId);
			}
		} catch (err) {
			console.log("err", err);
		}
	};
	if (campaignConfig.googleAnalyticsId) {
		return <LoadExternalScriptFile parentCallback={loadAnalyticsId} url={`https://www.googletagmanager.com/gtag/js?id=${campaignConfig.googleAnalyticsId}`} />;
	} else {
		return <></>;
	}
};

export default GoogleAnalytics;
