import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { uiconfig } from "../../redux/actions";

function Maintenance({ uiconfig }) {
    const { t } = useTranslation();
    const history = useHistory();
    const miniServiceHandler = async () => { 
        uiconfig()
            .then((response) => {                    
                if (response.httpCode === 200 && response.data.status === "active") {
                    toast.success(`${t("Thank you for your patience!")}.`);
                    history.push("/");				
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }
    useEffect(() => {
        miniServiceHandler();
        //Every 5 second call api
        let IntId = setInterval(() => {
            miniServiceHandler();
        },5000)
        
        return () => clearInterval(IntId);
	}, []);
    
    return (
        <div className="container d-flex align-items-center justify-content-center vh-100" style={{
            /* backgroundColor:'#d6433b',color:'#FFF' */
        }}>
            <div className="row text-center text-sm-start" style={{wordBreak:'break-all'}}>
                <div className='col-sm-12 col-md-12'>
                <img className='w-100' src="/public/webstatic/images/info-icon.png" alt=""/>
                <h1 className="display-5 fw-bold">We’ll be back soon!</h1>
                <p className="fs-3"> <span className="text-danger">:) Oops!</span> Temporarily down for maintenance.</p>
                <p className="fs-3">
                Sorry for the inconvenience. We’re performing some maintenance at the moment. 
                </p>
                <p>If you need to you can always <a href="mailto:#">contact us</a>, otherwise we&rsquo;ll be back online shortly!</p>
                <p className="text-muted">&mdash; The 3TL Team</p>
                </div>
                
                {/* <a href="/" className="btn btn-primary">Go Home</a> */}
                
            </div>
        </div>
      )
}

export default connect(null, { uiconfig })(Maintenance)