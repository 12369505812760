import React from "react";
import {Carousel, Container} from "react-bootstrap";
import {carouselData} from "../data";

const CarouselCustom  = () => {
	return (
		<Container className="carouselCustom" fluid>
			<div className="row justify-content-center" id="home">
				<Carousel>
					{
						carouselData.map((item, index) => {
							return (
								<Carousel.Item key={index}>
									<img
										className="d-block w-100"
										src={item.img}
										alt={item.title}
									/>
									{/* <Carousel.Caption>
										<h3>First slide label</h3>
										<p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
									</Carousel.Caption> */}
								</Carousel.Item>

								//{/* START: Login conditional banner: like DSR */}
								// <>
								// 	{!user.accessToken ? (
								// 		<a href="/register" className="w-100">
								// 			<img className="d-block w-100 banner-img desktop-hide" src={item.img} alt={item.title} />
								// 			<img className="d-block w-100 mobile-hide banner-img" src={item.mobile_img} alt={item.title} />
								// 		</a>
								// 	) : (
								// 		<>
								// 			<img className="d-block w-100  banner-img desktop-hide" src={item.img} alt={item.title} />
								// 			<img className="d-block w-100 mobile-hide banner-img" src={item.mobile_img} alt={item.title} />
								// 		</>
								// 	)}
								// </>
								//{/* END: Login conditional banner */}
							);
						})
					}
					
				</Carousel>
			</div>
		</Container>
	);
};

export default CarouselCustom;