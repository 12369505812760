import React, { useState, useEffect } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Container, Row, Col } from "react-bootstrap";
import IsLoadingHOC from "./../IsLoadingHOC";
import { getMyRewardsAction } from "./../../redux/actions";
import TableReport from "./../TableReport";
const MyRewards = (props) => {
	const { t } = useTranslation();
	const [rewardList, setRewardList] = useState([]);
	const columns = [
		{ dataField: "description", text: t("Description") },
		{ dataField: "date", text: t("Date") },
		{ dataField: "points", text: t("Points") },
	];
	useEffect(() => {
		props.setLoading(true);
		getMyRewardsAction(0, 5000)
			.then((responseData) => {
				if (responseData.httpCode === 200) {
					responseData.data.content = responseData.data.content.map((v) => ({ ...v, date: new Date(v.date).toLocaleDateString() }));
					setRewardList(responseData.data.content);
				} else {
					setRewardList([]);
					console.log("responseData", responseData);
				}
				props.setLoading(false);
			})
			.catch((err) => {
				props.setLoading(false);
				setRewardList([]);
				console.log("Response Error:", JSON.stringify(err));
			});
		// eslint-disable-next-line
	}, []);

	return (
		<div className="post-login">
			<Container>
				<Row xs={1} md={1} lg={1}>
					<Col>
						<h2>
							<Trans>My Rewards</Trans>
						</h2>
					</Col>
				</Row>
				<Row>
					<Col xs lg="12" className="text-center-">
						<TableReport searchTextboxEnable={false} keyField="eventId" data={rewardList} columns={columns} />
					</Col>
				</Row>
			</Container>
		</div>
	);
};

export default IsLoadingHOC(MyRewards, "Loading....");
