import axios from 'axios';
import { store } from '../redux/store';
import { saveAccessToken, saveRefreshToken, logoutFromLocalAction } from '../redux/actions';
const baseUrl = process.env.REACT_APP_API_BASE_URL;
//request interceptor to add the auth token header to requests
axios.interceptors.request.use(
  config => {
    let userLang = navigator.language || navigator.userLanguage;
    let lang = 'en';
    switch (userLang) {
      case 'en-GB':
        lang = 'en';
        break;
      case 'fr-CA':
        lang = 'fr';
        break;
      case 'es':
        lang = 'es';
        break;
      default:
        lang = 'en';
    }
    lang = localStorage.getItem('i18nextLng') ? localStorage.getItem('i18nextLng') : lang;
    const accessToken = store.getState('user').user.accessToken;
    if (accessToken) {
      config.headers['Authorization'] = accessToken;
    }
    config.headers['Accept-Language'] = lang;
    return config;
  },
  error => {
    Promise.reject(error);
  }
);

//response interceptor to refresh token on receiving token expired error
axios.interceptors.response.use(
  response => {
    return response;
  },
  async error => {
    const originalRequest = error.config;
    let refreshToken = store.getState('user').user.refreshToken;
    if (refreshToken && (error.response.status === 401 || error.response.data.httpCode === 401) && !originalRequest._retry && error.config.url !== `${baseUrl}/s2/auth/refreshtoken`) {
      originalRequest._retry = true;
      try {
        let res = await axios({ method: 'POST', url: `${baseUrl}/s2/auth/refreshtoken`, data: { refreshToken: refreshToken } });
        if (res['data']) {
          if (res.data.httpCode === 200) {
            store.dispatch(saveAccessToken(res.data.accessToken));
            store.dispatch(saveRefreshToken(res.data.refreshToken));
            return axios(originalRequest);
          }
        } else {
          store.dispatch(logoutFromLocalAction());
          Promise.reject(error);
        }
      } catch (err) {
        store.dispatch(logoutFromLocalAction());
        Promise.reject(error);
      }
    }
    return Promise.reject(error);
  }
);

class Api {
  post(path, data = null) {
    return this.send(path, 'post', data);
  }

  put(path, data = null) {
    return this.send(path, 'put', data);
  }

  get(path, data = null) {
    return this.send(path, 'get', data);
  }

  delete(path, data = null) {
    return this.send(path, 'delete', data);
  }

  send(url, method, data) {
    //console.log("lang", lang);
    let uri = `${baseUrl}${url}`;

    return new Promise((resolve, reject) => {
      let option = { method, url: uri, data: data };

      axios(option)
        .then(response => {
          return response.data;
        })
        .then(responseData => {
          // debugger;
          //console.log("data final", responseData);
          resolve(responseData);
        })
        .catch(error => {
          if (error['response']) {
            resolve(error.response.data);
          } else {
            resolve(error);
          }
        });
    });
  }
}

const api = new Api();
export default api;
//export default axios;
