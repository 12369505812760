import React, { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import { toast } from "react-toastify";
import IsLoadingHOC from "../../components/IsLoadingHOC";
import { connect } from "react-redux";
import errorHtml from "../../components/Error";
import { resetPassword, resetPasswordTokenValidationCheckAction } from "../../redux/actions";
import { useHistory, Redirect } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
const ResetPassword = ({ setLoading, resetPassword, location }) => {
	const { t } = useTranslation();
	const history = useHistory();
	const confige = useSelector((state) => state.app.config);
	const [passwordConfirm, passwordConfirmSet] = useState("");
	const [password, setPassword] = useState("");
	const [isValidToken, setIsValidToken] = useState(true);
	const [errors, setErrors] = useState({ passwordConfirm: "", password: "" });
	const [token, setToken] = useState("");
	const [passwordValidation, setPasswordValidation] = useState({});
	useEffect(() => {
		if (confige.uiConfig) {
			if (confige.uiConfig.passwordRule) {
				setPasswordValidation(confige.uiConfig.passwordRule);
			}
		}
	}, [confige, confige.uiConfig]);
	const validatePassword = (value, validationArray) => {
		let isValidPassword = true;
		for (let key in validationArray) {
			if (key === "minLength") {
				if (value.length < validationArray[key] && value.length !== 0) {
					setErrors({ ...errors, password: `${t("Should not be less then", { count: validationArray[key] })}.` });
					isValidPassword = false;
				}
			}
			if (key === "maxLength") {
				if (value.length > validationArray[key]) {
					setErrors({ ...errors, password: `${t("Should not be greater then", { count: validationArray[key] })}.` });
					isValidPassword = false;
				}
			}
			if (key === "mandatory" && validationArray[key]) {
				if (!value.length) {
					setErrors({ ...errors, password: `${t("Should not be empty")}.` });
					isValidPassword = false;
				}
			}
			if (key === "upperCaseReq" && validationArray[key]) {
				if (value.search(/[A-Z]/) < 0 && value.length !== 0) {
					setErrors({ ...errors, password: `${t("Should have atleast one Upper Case latter")}.` });
					isValidPassword = false;
				}
			}
			if (key === "lowerCaseReq" && validationArray[key]) {
				if (value.search(/[a-z]/) < 0 && value.length !== 0) {
					setErrors({ ...errors, password: `${t("Should have atleast one Lower Case latter")}.` });
					isValidPassword = false;
				}
			}
			if (key === "specialCharReq" && validationArray[key]) {
				// eslint-disable-next-line
				var format = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
				if (!format.test(value) && value.length !== 0) {
					setErrors({ ...errors, password: `${t("Should have atleast one special letter")}.` });
					isValidPassword = false;
				}
			}
		}
		return isValidPassword;
	};
	useEffect(() => {
		const query = new URLSearchParams(location.search);
		const token = query.get("token");
		if (!token) {
			setIsValidToken(false);
		} else {
			setLoading(true);
			resetPasswordTokenValidationCheckAction(token, {})
				.then((data) => {
					if (data.httpCode === 404) {
						setIsValidToken(false);
						toast.error(`${t("Your password reset link is invalid")}.`);
					}
					setLoading(false);
				})
				.catch((err) => {
					setLoading(false);
					setIsValidToken(false);
				});
		}
		setToken(token);
		// eslint-disable-next-line
	}, []);

	const onChangeHandle = (e) => {
		const { name, value } = e.target;
		if (name === "passwordConfirm") {
			if (password !== value && value.length) {
				setErrors({ ...errors, passwordConfirm: `${t("Password and confirm password should be same")}.` });
			} else {
				value.length < 1 ? setErrors({ ...errors, passwordConfirm: `${t("password")} ${t("Should not be empty")}.` }) : setErrors({ ...errors, passwordConfirm: "" });
			}
			passwordConfirmSet(value);
		}
		if (name === "password") {
			value.length < 1 ? setErrors({ ...errors, password: `${t("password")} ${t("Should not be empty")}.` }) : setErrors({ ...errors, password: "" });
			setPassword(e.target.value);
		}
	};

	const handleError = () => {
		let value = false;
		const error = {};
		if (password.length < 1) {
			error.password = `${t("password")} ${t("Should not be empty")}.`;
			value = true;
		}
		if (passwordConfirm.length < 1) {
			error.passwordConfirm = `${t("password confirm")} ${t("Should not be empty")}.`;
			value = true;
		}
		if (password !== passwordConfirm && passwordConfirm.length) {
			error.passwordConfirm = `${t("Password and confirm password should be same")}.`;
			value = true;
		} else if (!passwordConfirm.length) {
			error.passwordConfirm = `${t("password confirm")} ${t("Should not be empty")}.`;
			value = true;
		} else {
			error.passwordConfirm = "";
		}
		return { value, error };
	};

	const onSubmit = () => {
		const { value, error } = handleError();
		if (value) {
			setErrors({ ...errors, ...error });
			return false;
		}
		if (validatePassword(password, passwordValidation)) {
			setLoading(true);
			resetPassword({ token, password })
				.then((response) => {
					console.log(response);
					setLoading(false);
					//toast.success(response.message);
					toast.success(`${t("Your password is reset successfully. Please check your email")}.`);
					history.push("/login");
				})
				.catch((error) => {
					console.log(error);
					error.map((item) => {
						toast.error(item.message);
					});
					setLoading(false);
				});
		}
	};
	if (!isValidToken) {
		return <Redirect to={"login"} />;
	} else {
		return (
			<div className="page">
				<div className="container">
					<h1 className="main-heading">{`${t("Reset")} ${t("password")}`}</h1>
					<div className="row justify-content-center">
						<div className="col-md-6 col-sm-10">
							<form role="form">
								<div className="form-group row theme-form-password">
									<label htmlFor="inputPassword" className="col-sm-12 col-form-label">
										{t("password")}
									</label>
									<div className="col-sm-12">
										<input type="password" 
										data-test="reset-password-input" 
										name="password" 
										onChange={onChangeHandle} 
										className="form-control" 
										id="inputPassword" 
										placeholder={t("password")} 
										/>
									</div>
									<span className="input-error" role="alert">{errors.password}</span>
								</div>
								<div className="form-group row theme-form-password-confirm">
									<label htmlFor="inputPasswordConfirm" className="col-sm-12 col-form-label">
										{t("password confirm")}
									</label>
									<div className="col-sm-12">
										<input type="password" 
										data-test="reset-password-confirm-input" 
										name="passwordConfirm" 
										onChange={onChangeHandle} 
										className="form-control" 
										id="inputPasswordConfirm" 
										placeholder={t("password confirm")} 
										/>
									</div>
									<span className="input-error" role="alert">{errors.passwordConfirm}</span>
								</div>
								<div className="row">
									<button onClick={onSubmit} data-test="reset-password-submit" type="button" className="btn btn-primary">
										{t("Save")}
									</button>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
		);
	}
};

export default connect(null, { resetPassword })(IsLoadingHOC(ResetPassword, "Wait ...."));
