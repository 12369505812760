import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import common_fr from './translations/fr_language_bundle.json';
import common_es from './translations/sp_language_bundle.json';
import common_en from './translations/en_language_bundle.json';
let lang = 'en';
if (localStorage.getItem('i18nextLng')) {
  lang = localStorage.getItem('i18nextLng');
} else {
  var userLang = navigator.userLanguage || navigator.language;
  switch (userLang) {
    case 'en-GB':
      lang = 'en';
      break;
    case 'fr-CA':
      lang = 'fr';
      break;
    case 'es':
      lang = 'es';
      break;
    default:
      lang = 'en';
  }
}
const resources = {
  en: {
    translation: JSON.parse(JSON.stringify(common_en)),
  },
  fr: {
    translation: JSON.parse(JSON.stringify(common_fr)),
  },
  es: {
    translation: JSON.parse(JSON.stringify(common_es)),
  },
};
i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    resources,
    lng: lang,
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
