import React, { useState } from 'react';
import { Card, Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import IsLoadingHOC from '../../components/IsLoadingHOC';
import { connect, useSelector } from 'react-redux';
import errorHtml from '../../components/Error';
import { login } from '../../redux/actions';
import { useHistory } from 'react-router-dom';
import SocialLogin from '../../components/SocialLogin';
import { Trans, useTranslation } from 'react-i18next';
import SecondFactorAuth from '../../components/SecondFactorAuth';
import FingerprintJS from '@fingerprintjs/fingerprintjs-pro';
import Captcha from '../../components/Captcha';
const Login = ({ setLoading, login }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const confige = useSelector(state => state.app.config);
  const [username, setUserName] = useState('');
  const [password, setPassword] = useState('');
  const [showSecondFactorPopup, setShowSecondFactorPopup] = useState(false);
  const [secondFactorAuthData, setSecondFactorAuthData] = useState({});
  const [errors, setErrors] = useState({ username: '', password: '', captchaVal: '' });
  const [fpData, setFpData] = useState({ visitorId: '', requestId: '' });
  let [captchaValue, setCaptchaValue] = useState(false);
  let [captchaEnable, setCaptchaEnable] = useState(false);
  const [refreshReCaptcha, setRefreshReCaptcha] = useState({ state: null, token: null, requestBody: null });
  const onChangeHandle = e => {
    const { name, value } = e.target;
    if (name === 'username') {
      setUserName(e.target.value);
      value.length < 1 ? setErrors({ ...errors, username: `${t('User Name should not be empty')}.` }) : setErrors({ ...errors, username: '' });
    }
    if (name === 'password') {
      value.length < 1 ? setErrors({ ...errors, password: `${t('Password Should not be empty')}.` }) : setErrors({ ...errors, password: '' });
      setPassword(e.target.value);
    }
  };
  const [redirectUrl, setRedirectUrl] = useState('/');
  React.useEffect(() => {
    if (confige.fingerprinting) {
      if (confige.fingerprinting.enable) {
        const fpPromise = FingerprintJS.load({ apiKey: confige.fingerprinting.clientKey });
        // Get the visitor identifier when you need it.
        fpPromise
          .then(fp => fp.get())
          .then(result => {
            setFpData({ visitorId: result.visitorId, requestId: result.requestId });
          });
      }
    }
    if (confige.captchaConfig) {
      if (confige.captchaConfig.loginCaptcha) {
        setCaptchaEnable(true);
      }
    }
  }, [confige]);
  React.useEffect(() => {
    let errorArray = JSON.parse(JSON.stringify(errors));
    if (captchaValue) {
      errorArray['captchaVal'] = '';
    }
    setErrors(errorArray);
    // eslint-disable-next-line
  }, [captchaValue]);
  React.useEffect(() => {
    const query = new URLSearchParams(window.location.search);
    const redtUrl = query.get('route') ? query.get('route') : '/';
    if (redirectUrl !== redtUrl) {
      setRedirectUrl(redtUrl);
    }
    // eslint-disable-next-line
  }, [window.location]);

  React.useEffect(() => {
    if (captchaEnable && refreshReCaptcha.state === 'v3' && refreshReCaptcha.token) {
      let requestBody = refreshReCaptcha.requestBody;
      requestBody['captchaVal'] = refreshReCaptcha.token;
      apiHandler(requestBody);
    }
  }, [refreshReCaptcha]);

  const handleError = () => {
    let value = false;
    const error = {};
    if (username.length < 1) {
      error.username = `${t('User Name should not be empty')}.`;
      value = true;
    }
    if (password.length < 1) {
      error.password = `${t('Password Should not be empty')}.`;
      value = true;
    }
    if (password.length < 1) {
      error.password = `${t('Password Should not be empty')}.`;
      value = true;
    }
    if (!captchaValue && captchaEnable) {
      error.captchaVal = `${t('Please select captcha')}.`;
      value = true;
    }
    return { value, error };
  };

  const onSubmit = async () => {
    const { value, error } = handleError();
    if (value) {
      setErrors({ ...errors, ...error });
      return false;
    }
    setLoading(true);

    let requestBody = { username, password, visitorId: fpData.visitorId, requestId: fpData.requestId };
    if (captchaEnable && confige.captchaConfig && confige.captchaConfig.recaptchaVersion === 'V3') {
      setRefreshReCaptcha({ ...refreshReCaptcha, state: 'init', requestBody });
      return;
    } //confige.captchaConfig.loginCaptcha
    if (captchaEnable) {
      requestBody['captchaVal'] = captchaValue;
    }
    apiHandler(requestBody);
  };

  const apiHandler = requestBody => {
    login(requestBody)
      .then(response => {
        setLoading(false);
        if (response.httpCode === 204) {
          setSecondFactorAuthData({ id: response.data });
          setShowSecondFactorPopup(true);
        } else {
          toast.success(`${t('User login successfully')}.`);
          history.push(redirectUrl);
        }
      })
      .catch(error => {
        console.log('Error in login', error);
        if (error['httpCode'] === 403) {
          toast.error(`${t('Your account is suspended. Please contact helpdesk for more information')}.`);
        } else if (error['httpCode'] === 406) {
          toast.error(`${t('Error')}! ${t('User not verified')}.`);
        } else {
          toast.error(`${t('Invalid credentials')}.`);
        }
        setLoading(false);
      });
  };
  return (
    <div className="page">
      <div className="container">
        <h1 className="main-heading">
          <Trans>login</Trans>
        </h1>
        <div className="row justify-content-center">
          <div className="col-md-6 col-sm-10">
            <form role="form">
              <div className="form-group row">
                <label htmlFor="staticEmail" className="col-sm-12 col-form-label">
                  <Trans>lang_key_username</Trans>/<Trans>lang_key_email</Trans>
                </label>
                <div className="col-sm-12">
                  <input type="text" data-test="sign-in-username-input" name="username" onChange={onChangeHandle} className="form-control" id="staticEmail" placeholder={`${t('Enter ')}${t('lang_key_username')}/${t('lang_key_email')}`} aria-autocomplete="email" />
                  <span className="input-error" role="alert">
                    {errors.username}
                  </span>
                </div>
              </div>
              {/*[START:PASSWORD]*/}
              <div className="form-group row">
                <label htmlFor="inputPassword" className="col-sm-12 col-form-label">
                  <Trans>password</Trans>
                </label>
                <div className="col-sm-12">
                  <input type="password" data-test="sign-in-password-input" name="password" onChange={onChangeHandle} className="form-control" id="inputPassword" placeholder={`${t('Enter ')}${t('password')}`} onKeyPress={e => e.key === 'Enter' && onSubmit()} />
                  <span className="input-error" role="alert">
                    {errors.password}
                  </span>
                </div>
              </div>
              {/*[END:PASSWORD]*/}
              {/* FORGOT PASSWORD */}
              <div className="forgot-pass-links">
                <a href="/forget-password">
                  <Trans>forgot password</Trans>
                </a>
              </div>
              {/* END FORGOT PASSWORD */}
              {/*[START:GOOGLECAPTCHA]*/}
              {captchaEnable ? (
                <div className="form-group row">
                  <label htmlFor="g-recaptcha-response" className="col-sm-12 invisible" aria-hidden="true">
                    Google captcha
                  </label>
                  <div className="col-sm-12">
                    <Captcha align="left" reset={refreshReCaptcha?.state} parentCallback={setCaptchaValue} v3Callback={setRefreshReCaptcha} />
                    <span className="input-error" role="alert">
                      {errors.captchaVal}
                    </span>
                  </div>
                </div>
              ) : (
                ''
              )}
              {/*[END:GOOGLECAPTCHA]*/}
              <div className="col-md-12 text-center mt-5 mb-4">
                <button onClick={onSubmit} data-test="sign-in-sign-in-button" type="button" className="btn btn-primary px-5">
                  <Trans>login</Trans>
                </button>
              </div>
              <div className="my-3">
                <SocialLogin />
              </div>
              <div className="register-links text-center mt-4">
                <div className="mb-3">
                  <Trans>or</Trans>
                </div>
                <a href="/register">
                  <Trans>click here to register your account</Trans>.
                </a>
              </div>
            </form>
          </div>
        </div>
      </div>
      <Modal show={showSecondFactorPopup}>
        <Modal.Body>{showSecondFactorPopup ? <SecondFactorAuth otpLen={6} waitingTime={120} data={secondFactorAuthData} /> : ''}</Modal.Body>
      </Modal>
    </div>
  );
};

export default connect(null, { login })(IsLoadingHOC(Login, 'Wait ....'));
