import React, { useState, useEffect } from 'react';
import { useParams, Redirect, Link } from 'react-router-dom';
import { rewardsListAction, redeemRewardOrderAction, balanceGetAction, rewardsOrderAddressUpdateAction } from '../../redux/actions';
import { Container, Col, Row, Button, Card, Modal } from 'react-bootstrap';
import { Trans, useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import IsLoadingHOC from '../IsLoadingHOC';
import { useSelector, useDispatch } from 'react-redux';
import BraintreeDropin from '../PaymentGateways/BraintreeDropin';
import ViewOrder from './ViewOrder';
import GooglePlaces from '../GooglePlaces';
const RewardsPrev = props => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const user = useSelector(({ user }) => user);
  const [pageRedirect, setPageRedirect] = useState(false);
  const [showModel, setShowModel] = useState(false);
  const [showPaymentGeteways, setShowPaymentGeteways] = useState(false);
  const [orderData, setOrderData] = useState(false);
  const [orderDisplay, setOrderDisplay] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [data, setData] = useState({});
  const [place, setPlace] = useState({});
  const [newAddress, setNewAddress] = useState({ address: '', country: '', state: '', suite: '', city: '', zip: '' });
  let { id } = useParams();
  if (!id) {
    toast.error(`${t('Error')}: ${t('Requested entity not found')}!`);
    setPageRedirect(true);
  }
  useEffect(() => {
    props.setLoading(true);
    dispatch(balanceGetAction());
    rewardsListAction(id)
      .then(response => {
        if (typeof response.data === 'object') {
          setData(response.data);
        } else {
          toast.error(`${t('Error')}: ${t('Requested entity not found')}!`);
          setPageRedirect(true);
        }
        props.setLoading(false);
      })
      .catch(err => {
        toast.error(`${t('Error')}: ${t('Requested entity not found')}!`);
        setPageRedirect(true);
        props.setLoading(false);
      });
    // eslint-disable-next-line
  }, [id]);
  const rewardBtnClick = e => {
    if (data.type.toUpperCase() === 'PHYSICAL' || data.type.toUpperCase() === 'DIGITAL') {
      props.setLoading(true);
      redeemRewardOrderAction(id)
        .then(response => {
          if (response.data) {
            dispatch(balanceGetAction());
            if (data.type.toUpperCase() === 'DIGITAL') {
              setShowPopup(true);
            } else {
              setOrderData(response.data);
              if (response.data.shippingAddress) {
                let { shippingAddress } = response.data;
                let address = {
                  address: shippingAddress.address ? shippingAddress.address : '',
                  country: shippingAddress.country ? shippingAddress.country : '',
                  state: shippingAddress.state ? shippingAddress.state : '',
                  suite: shippingAddress.suite ? shippingAddress.suite : '',
                  city: shippingAddress.city ? shippingAddress.city : '',
                  zip: shippingAddress.zip ? shippingAddress.zip : '',
                };
                setNewAddress(address);
              }
              setOrderDisplay(true);
            }
          } else {
            if (response.httpCode === 406 && data.type.toUpperCase() === 'DIGITAL') {
              toast.error(`${t('Sorry')}! ${t("You've already redeemed this reward")}.`);
            } else if (response.httpCode === 403) {
              toast.error(`${t('Sorry')}! ${t('This reward is temporarily out of stock')}.`);
            } else {
              toast.error(`${t('Error')}: ${t('Something went wrong')}!`);
            }
          }
          props.setLoading(false);
        })
        .catch(err => {
          toast.error(`${t('Error')}: ${t('Something went wrong')}!`);
          props.setLoading(false);
        });
    } else {
      toast.success(`${t('Redeem')} ${t('Rewards')}`);
    }
  };
  const orderAction = (makePayment, goBack = false) => {
    if (makePayment) {
      if (orderData.shippingAddress) {
        if (!orderData.shippingAddress.suite || !orderData.shippingAddress.address || !orderData.shippingAddress.city || !orderData.shippingAddress.country || !orderData.shippingAddress.zip) {
          toast.error(`${t('Error')}: ${t('Entered wrong address for delivery')}, ${t('Please update valid address first')}.`);
          return false;
        }
      } else {
        toast.error(`${t('Error')}: ${t('Entered wrong address for delivery')}, ${t('Please update valid address first')}.`);
        return false;
      }
      setShowPaymentGeteways(true);
    } else if (goBack) {
      setOrderDisplay(false);
    }
  };
  const saveAddress = e => {
    if (newAddress.suite.length < 1) {
      toast.error(`${t('Error')}: ${t('Invalid')} ${t('lang_key_add_suite')}!`);
      return false;
    } else if (newAddress.address.length < 5) {
      toast.error(`${t('Error')}: ${t('Invalid')} ${t('lang_key_add_address')}!`);
      return false;
    } else if (newAddress.city.length < 3) {
      toast.error(`${t('Error')}: ${t('Invalid')} ${t('City')}!`);
      return false;
    } else if (newAddress.state.length < 3) {
      toast.error(`${t('Error')}: ${t('Invalid')} ${t('lang_key_add_state')}!`);
      return false;
    } else if (newAddress.country.length < 3) {
      toast.error(`${t('Error')}: ${t('Invalid')} ${t('lang_key_add_country')}!`);
      return false;
    } else if (newAddress.zip.length < 4) {
      toast.error(`${t('Error')}: ${t('Invalid')} ${t('lang_key_add_zip')}!`);
      return false;
    }
    props.setLoading(true);
    let requestData = { address: newAddress.address, suite: newAddress.suite, city: newAddress.city, state: newAddress.state, country: newAddress.country, zip: newAddress.zip };
    rewardsOrderAddressUpdateAction(orderData.id, requestData)
      .then(response => {
        if (response.httpCode === 200 && typeof response.data === 'object') {
          if (response.data.shippingAddress) {
            let tempOrderData = JSON.parse(JSON.stringify(orderData));
            let { shippingAddress } = response.data;
            tempOrderData.shippingAddress = shippingAddress;
            setOrderData(tempOrderData);
          }
          setShowModel(false);
          toast.success(`${t('Shipping')} ${t('lang_key_add_address')}: ${t('updated successfully')}.`);
        } else {
          toast.error(`${t('Error')}: ${t('Something went wrong')}!, ${t('Please try again')}.`);
        }
        props.setLoading(false);
      })
      .catch(err => {
        toast.error(`${t('Error')}: ${t('Something went wrong')}!, ${t('Please try again')}.`);
        props.setLoading(false);
      });
  };
  const handleChange = e => {
    let tempAddress = JSON.parse(JSON.stringify(newAddress));
    let name = e.target.name;
    if (name === 'zip') {
      if (isNaN(e.target.value)) {
        return false;
      }
    }
    tempAddress[name] = e.target.value;
    setNewAddress(tempAddress);
  };
  useEffect(() => {
    if (place.hasOwnProperty('address')) {
      let curStateData = Object.assign({}, newAddress);
      curStateData.address = place.address;
      curStateData.country = place.country;
      curStateData.state = place.state;
      curStateData.city = place.city;
      curStateData.suite = place.street;
      curStateData.zip = place.zip;
      setNewAddress(curStateData);
    }
    // eslint-disable-next-line
  }, [place]);
  const googleSelectedAddressHandler = place => {
    setPlace(place);
  };
  if (pageRedirect) {
    return <Redirect to="/rewards" />;
  } else if (showPaymentGeteways) {
    return (
      <Container className="rewards-list-container">
        <BraintreeDropin data={orderData} />
      </Container>
    );
  } else {
    return (
      <Container className="rewards-list-container">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item">
              <Link id="headerParentLink" className="parent-link" to="/rewards">
                <Trans>Rewards</Trans>
              </Link>
            </li>
            <li class="breadcrumb-item active" aria-current="page">
              {data['itemName'] ? data.itemName : ''}
            </li>
          </ol>
        </nav>
        <div className="post-login">
          <Row xs={1} md={2} lg={2} className="pt-5">
            <Col>
              <Card>
                <Card.Img variant="top" src={data.image} />
              </Card>
            </Col>
            <Col>
              {orderData && orderDisplay ? (
                <ViewOrder data={orderData}>
                  <Row>
                    <Col md={{ span: 4, offset: 2 }} sm={{ span: 4, offset: 2 }}>
                      <Button id={`redeem-now-btn`} className="cart-button btn btn-primary" onClick={e => orderAction(true, false)}>
                        <Trans>Continue</Trans>
                      </Button>
                    </Col>
                    <Col md={{ span: 6 }} sm={{ span: 6 }}>
                      <Button id={`update-address-btn`} className="cart-button btn btn-warning" onClick={e => setShowModel(true)}>
                        <Trans>Update</Trans> <Trans>Shipping</Trans> <Trans>lang_key_add_address</Trans>
                      </Button>
                    </Col>
                  </Row>
                </ViewOrder>
              ) : (
                <>
                  <h4>{data['itemName'] ? data.itemName : ''}</h4>
                  <p className="description">{data['itemDescription'] ? data.itemDescription : ''}</p>
                  <p>
                    <strong>
                      <Trans>Redeem Now</Trans> <Trans>With</Trans> {data['redeemPoints'] ? data.redeemPoints : 0} <Trans>Points</Trans>
                    </strong>
                  </p>
                  <div className="text-center py-4">
                    {Object.keys(data).length > 0 ? (
                      <Button disabled={user.pointBalance >= data.redeemPoints ? '' : true} rewards-item={data.itemId} id={`redeem-btn-${data.id}`} className="btn btn-primary" onClick={rewardBtnClick}>
                        {user.pointBalance >= data.redeemPoints ? `${t('Redeem')} ${t('Rewards')}` : t('Unlock With More Points')}
                      </Button>
                    ) : (
                      ''
                    )}
                  </div>
                </>
              )}
            </Col>
          </Row>
        </div>
        <Modal size="lg" show={showModel} animation={false}>
          <Modal.Header>
            <Modal.Title>
              <Trans>Update</Trans> <Trans>Shipping</Trans> <Trans>lang_key_add_address</Trans>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="form-group row theme-signup-address-finder">
              <label htmlFor="addressFinder" className="col-sm-2 col-form-label">
                <Trans>lang_key_select_address</Trans>
              </label>
              <div className="col-sm-10">
                <GooglePlaces getPlaceHandler={googleSelectedAddressHandler} placeholder={t('lang_key_select_address')} />
              </div>
            </div>

            <div className="form-group row theme-signup-suite">
              <label htmlFor="suite" className="col-sm-2 col-form-label">
                <Trans>lang_key_add_suite</Trans>
              </label>
              <div className="col-sm-10">
                <input type="text" maxLength="20" data-test="register-state" value={newAddress.suite} name="suite" onChange={handleChange} className="form-control" id="suite" placeholder={t('lang_key_add_suite')} />
              </div>
            </div>
            <div className="form-group row theme-signup-address">
              <label htmlFor="address" className="col-sm-2 col-form-label">
                <Trans>lang_key_add_address</Trans>
              </label>
              <div className="col-sm-10">
                <input type="text" maxLength="100" value={newAddress.address} data-test="register-address" name="address" className="form-control" id="address" placeholder={t('lang_key_add_address')} />
              </div>
            </div>
            <div className="form-group row theme-signup-city">
              <label htmlFor="city" className="col-sm-2 col-form-label">
                <Trans>City</Trans>
              </label>
              <div className="col-sm-10">
                <input type="text" maxLength="50" data-test="signup-city" value={newAddress.city} name="city" onChange={handleChange} className="form-control" id="city" placeholder={t('City')} />
              </div>
            </div>
            <div className="form-group row theme-signup-state">
              <label htmlFor="state" className="col-sm-2 col-form-label">
                {t('lang_key_add_state')}
              </label>
              <div className="col-sm-10">
                <input type="text" maxLength="50" value={newAddress.state} data-test="register-state" name="state" onChange={handleChange} className="form-control" id="state" placeholder={t('lang_key_add_state')} />
              </div>
            </div>
            <div className="form-group row theme-signup-country">
              <label htmlFor="country" className="col-sm-2 col-form-label">
                <Trans>lang_key_add_country</Trans>
              </label>
              <div className="col-sm-10">
                <input type="text" maxLength="50" value={newAddress.country} data-test="register-country" name="country" onChange={handleChange} className="form-control" id="country" placeholder={t('lang_key_add_country')} />
              </div>
            </div>
            <div className="form-group row theme-signup-zip">
              <label htmlFor="zip" className="col-sm-2 col-form-label">
                <Trans>Zip</Trans>
              </label>
              <div className="col-sm-10">
                <input type="text" maxLength="6" data-test="signup-zip" value={newAddress.zip} name="zip" onChange={handleChange} className="form-control" id="zip" placeholder={t('Zip')} />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={e => setShowModel(false)}>
              <Trans>Close</Trans>
            </Button>
            <Button variant="primary" onClick={saveAddress}>
              <Trans>Save</Trans>
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal show={showPopup} onHide={e => setShowPopup(false)}>
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
          </Modal.Header>
          <Modal.Body className="show-grid">
            <Container>
              <Row>
                <Col xs={12} md={12} className="text-center">
                  <img alt="Congrates" src="/public/webstatic/images/vip-modal.png" width="200" />
                </Col>
                <Col xs={12} md={12} className="text-center">
                  <Trans>You've redeemed a reward</Trans>!
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={12} className="text-center">
                  <Trans>Please check your email for details</Trans>.
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={12} className="text-center">
                  <Link id="href-goback" to="/rewards" className="validate-purchase-href-model-link" onClick={e => setShowPopup(false)}>
                    <Trans>GO BACK</Trans>
                  </Link>
                </Col>
              </Row>
            </Container>
          </Modal.Body>
        </Modal>
      </Container>
    );
  }
};
export default IsLoadingHOC(RewardsPrev, 'Loading....');
