import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import { Container, Row, Col, Button, Card } from 'react-bootstrap';
import IsLoadingHOC from '../IsLoadingHOC';
import { connect, useSelector } from 'react-redux';
import { spinAction } from '../../redux/actions';
import { toast } from 'react-toastify';
const MysteryBox = props => {
  const history = useHistory();
  const contestData = props.data;
  const user = useSelector(({ user }) => user);
  const { t } = useTranslation();
  const [lowPoint, setLowPoint] = useState(false);
  useEffect(() => {
    if (user.pointBalance < contestData.pointRequired) {
      setLowPoint(true);
    }
    // eslint-disable-next-line
  }, [props]);
  const setLoading = props.setLoading;
  let { id } = useParams();

  const enterNowBtnClickHandler = e => {
    setLoading(true);
    let data = {
      parentId: '',
      contestId: id,
    };
    props
      .spinAction(data, 'post', props.urlType)
      .then(responseData => {
        setLoading(false);
        if (responseData.httpCode === 406) {
          toast.error(`${t(`Oops`)}! ${t(`Low On Points`)}.`);
          setLowPoint(true);
        } else if (responseData.httpCode === 403) {
          toast.error(`${t(`Oops`)}! ${t(`You have reached the maximum limit`)}.`);
          window.location.reload();
        } else if (responseData.httpCode >= 200 && responseData.httpCode <= 299) {
          toast.success(`Thanks for entering!`);
          history.push('/points-history');
        } else {
          toast.error(`${t(`Something went wrong`)}.`);
        }
      })
      .catch(err => {
        setLoading(false);
        if (typeof err === 'object') {
          if (err.httpCode === 406) {
            toast.error(`${t(`Oops`)}! ${t(`Low On Points`)}.`);
            setLowPoint(true);
          } else if (err.httpCode === 403) {
            toast.error(`${t(`Oops`)}! ${t(`You have reached the maximum limit`)}.`);
            window.location.reload();
          } else {
            console.log('Response Error:', JSON.stringify(err));
            toast.error(`${t(`Something went wrong`)}.`);
          }
        } else {
          console.log('Response Error:', JSON.stringify(err));
          toast.error(`${t(`Something went wrong`)}.`);
        }
      });
  };

  return (
    <Container className="rewards-list-container">
      <div className="post-login">
        <Row xs={1} md={2} lg={2} className="pt-5">
          <Col>
            <Card>
              <Card.Img variant="top" src={contestData.cardHeaderImage} />
            </Card>
          </Col>
          <Col>
            <h3 className="text-left mt-3">{contestData['name'] ? contestData.name : ''}</h3>
            <p className="description">{contestData['description'] ? contestData.description : ''}</p>
            <p>
              <a className="green-text-link" title="See Official Terms & Conditions" href="/public/assets/Official Rules for Feb 2023 Equine Mystery Box Giveaway.pdf" target="_blank">
                See Official Terms & Conditions
              </a>
            </p>

            <div className="text-center text-sm-left py-4">
              {Object.keys(contestData).length > 0 ? (
                <Button disabled={user.pointBalance >= contestData.pointRequired ? '' : true} id={`contest-btn-${contestData.id}`} onClick={enterNowBtnClickHandler} className="btn btn-primary">
                  {user.pointBalance >= contestData.pointRequired ? `${t('Enter')} ${t('Now')}` : t('Unlock With More Points')}
                </Button>
              ) : (
                ''
              )}
            </div>
          </Col>
        </Row>
      </div>
    </Container>
  );
};
export default React.memo(connect(null, { spinAction })(IsLoadingHOC(MysteryBox, 'Loading....')));
