import React, { useEffect, useState } from "react";
import Card from "./Card";
import { connect } from "react-redux";
import { Redirect, Link } from "react-router-dom";
import { portalPointAction, activityStatusAction } from "../redux/actions";
import { Button, Container, Col, Row, Modal } from "react-bootstrap";
import IsLoadingHOC from "../components/IsLoadingHOC";
import { Trans } from "react-i18next";
const PortalPointList = (props) => {
	const [data, setData] = useState([]);
	const [popupShow, setPopupShow] = useState(false);
	const [viewMore, setViewMore] = useState(true);
	const [page, setPage] = useState(0);
	const [pageRedirect, setPageRedirect] = useState(false);
	const pagesize = props.pagesize ? props.pagesize : 4;
	useEffect(() => {
		getNextData(0);
		// eslint-disable-next-line
	}, []);
	const getNextData = (page) => {
		const actionType = ["view", "survey", "refer", "read", "share"];
		props.setLoading(true);
		props
			.portalPointAction(page + 1, actionType, pagesize)
			.then((response) => {
				let activityList = response.data;
				if (activityList) {
					activityList = activityList.map((v) => {
						let o = Object.assign({}, v);
						if (o.isTierEnabled && o.tierPoints) {
							o.points = o.tierPoints;
						}
						return o;
					});
				}
				setData([...data, ...activityList]);
				setPage(page + 1);
				setViewMore(response.end);
				props.setLoading(false);
			})
			.catch((ex) => {
				console.log("Error In API Call", ex);
				props.setLoading(false);
			});
	};
	const activityCLick = async (activityData, redirectUrl) => {
		try {
			if (activityData["actionType"] === "survey") {
				props.setLoading(true);
				let activityStatus = await activityStatusAction(activityData.id);
				if (activityStatus["allow"]) {
					setPageRedirect(redirectUrl);
				} else {
					setPopupShow(true);
					console.log("not allow", activityStatus);
				}
				props.setLoading(false);
			} else {
				setPageRedirect(redirectUrl);
			}
		} catch (err) {
			console.log("Error in Activity status check", err);
			props.setLoading(false);
		}
	};
	const handlePopupClose = () => {
		setPopupShow(false);
	};
	if (pageRedirect) {
		return <Redirect push to={pageRedirect} />;
	} else {
		return (
			<Container fluid>
				<div className="post-login">
					<Container fluid>
						<h2>
							<Trans>EARN POINTS WITH NEW OFFERS</Trans>
						</h2>
					</Container>
					<Container fluid>
						<Row xs={1} md={2} lg={4} className="card_holder">
							{data.map((item, index) => {
								return (
									<Col key={index}>
										<Card>
											<div onClick={(e) => activityCLick(item, `/earnpoints-action-preview/${item.id}`)} className="activity-block">
												<div className="activity-img-holder d-flex align-items-center">
													<img src={item.cardHeaderImage} alt="Avatar" style={{ width: "100%" }} />
												</div>
												<div className="activity-info">
													<div className="points">
														<Trans>Points</Trans>: {item.points}
													</div>
													<h5>
														{item.title}
													</h5>
												</div>
												<div className="row justify-content-center">
													<Button activity-type={item.actionType} id={`earn-btn-${item.id}`} className="cart-button btn btn-primary col-10">
														<Trans>Earn Now</Trans>
													</Button>
												</div>
											</div>
										</Card>
									</Col>
								);
							})}
						</Row>
						{viewMore && (
							<div className="text-center col my-4">
								{" "}
								<Button variant="secondary" id="view-more-earn-pointlist-btn" className="viewMore" onClick={() => getNextData(page)}>
									<Trans>View More</Trans>
								</Button>{" "}
							</div>
						)}
					</Container>
				</div>
				<Modal show={popupShow} onHide={handlePopupClose}>
					<Modal.Header closeButton>
						<Modal.Title>
							<Trans>Sorry</Trans>
						</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<div className="survey-message-div">
							<p className="alignCenter">
								<Trans>You have already earned the maximum number of points possible for this action</Trans>.
							</p>
						</div>
					</Modal.Body>
					<p align="center">
						<Link to={"#"} className="btn btn-link" onClick={handlePopupClose}>
							<Trans>Close this window</Trans>
						</Link>
					</p>
					<p align="center">
						<Link className="btn btn-link" onClick={handlePopupClose} to="/">
							<Trans>Go back to home</Trans>
						</Link>
					</p>
				</Modal>
			</Container>
		);
	}
};

export default connect(null, { portalPointAction })(IsLoadingHOC(PortalPointList, "Loading...."));
