import React, { useEffect, useState } from "react";
import { ticketsAction } from "../../redux/actions";
import { Button, Container, Col, Row, Table } from "react-bootstrap";
import IsLoadingHOC from "../IsLoadingHOC";
import { Trans, useTranslation } from "react-i18next";
import NewTicket from "./NewTicket";
import { Link } from "react-router-dom";

const TicketList = (props) => {
	const { t } = useTranslation();
	const pageLimit = 8;
	const [pageNo, setPageNo] = useState(0);
	const [viewPaginationBtn, setViewPaginationBtn] = useState(false);
	const [createTicket, setCreateTicket] = useState(false);
	const [data, setData] = useState([]);
	const [ticketSelectBox, setTicketSelectBox] = useState("");
	let [ticketListData, setTicketListData] = useState([]);

	const statusList = [
		{ key: "active", name: "Active" },
		{ key: "on-hold", name: "On Hold" },
		{ key: "waiting", name: "Waiting on customer" },
		{ key: "solved", name: "Solved" },
		{ key: "closed", name: "Closed" },
	];

	useEffect(() => {
		getTicketList();
		// eslint-disable-next-line
	}, []);
	const getTicketList = () => {
		props.setLoading(true);
		setPageNo(0);
		ticketsAction()
			.then((response) => {
				if (response.data) {
					let ticket = response.data.content;
					setTicketListData(ticket);
				}
				props.setLoading(false);
			})
			.catch((ex) => {
				console.log("Error In API Call", ex);
				props.setLoading(false);
			});
	};
	const getNextPageData = () => {
		let tempTicketList = JSON.parse(JSON.stringify(ticketListData));
		if (ticketSelectBox !== "") {
			tempTicketList = tempTicketList.filter((v) => v.status === ticketSelectBox);
		}
		let pageData = tempTicketList.slice(0, pageNo * pageLimit + pageLimit);
		setData(pageData);
		if (pageNo * pageLimit + pageLimit >= tempTicketList.length) {
			setViewPaginationBtn(false);
		} else {
			setViewPaginationBtn(true);
		}
	};
	const filterRewardList = async (e) => {
		setPageNo(0);
		setTicketSelectBox(e.target.value);
	};
	useEffect(() => {
		getNextPageData();
		// eslint-disable-next-line
	}, [ticketListData, ticketSelectBox, pageNo]);
	const newTicketBtnClicked = (reloadTicket, newTicketData = {}) => {
		if (reloadTicket) {
			if (newTicketData) {
				let temparr = {
					id: newTicketData.id ? newTicketData.id : "",
					assignedTo: newTicketData.assignedTo ? newTicketData.assignedTo : {},
					preview: newTicketData.preview ? newTicketData.preview : "",
					priority: newTicketData.priority ? newTicketData.priority : "",
					status: newTicketData.state ? newTicketData.state : "active",
					subject: newTicketData.subject ? newTicketData.subject : new Date().toLocaleString(),
					isRead: newTicketData.isRead ? newTicketData.isRead : false,
					numThreads: 1,
					createdAt: newTicketData.createdAt ? newTicketData.createdAt : new Date().toLocaleString(),
					updatedAt: newTicketData.updatedAt ? newTicketData.updatedAt : new Date().toLocaleString(),
				};
				let tempTicketList = JSON.parse(JSON.stringify(ticketListData));
				tempTicketList.unshift(temparr);
				setTicketListData(tempTicketList);
				setPageNo(0);
			}
		}
		setCreateTicket(false);
	};
	if (createTicket) {
		return <NewTicket parentCallback={newTicketBtnClicked} />;
	} else {
		return (
			<Container className="help-desk-container">
				<div className="post-login">
						<h2>
							<Trans>All</Trans> <Trans>Support</Trans> <Trans>Tickets</Trans>
						</h2>
						<Row>
							<Col xs={5} md={4} className="text-left">
								<Button variant="primary" onClick={(e) => setCreateTicket(true)} disabled={createTicket ? true : false} id="new-ticket-btn">
									<Trans>New</Trans> <Trans>Ticket</Trans>
								</Button>
							</Col>
							<Col xs={7} md={8}>
								<Row className="justify-content-end">
									<Col xs={5} md={7} className="text-right pr-0">
										<label htmlFor="ticketStatus" className="mt-2">
											Ticket Status
										</label>
									</Col>
									<Col xs={4} md={4} className="pr-0 pr-sm-3">
										<div className="form-group">
											<select className="form-control" id="ticketStatus" value={ticketSelectBox} onChange={filterRewardList}>
												<option value="">{`${t("Show")} ${t("All")}`}</option>
												{statusList.map((v, i) => {
													return (
														<option key={i + "_typeOption"} value={v.key}>
															{t(v.name)}
														</option>
													);
												})}
											</select>
										</div>
									</Col>
									<Col xs={3} md={1}>
										{" "}
										<Button title={`${t("Reload")} ${t("Tickets")} `} variant="info" onClick={(e) => getTicketList()} id="reload-ticket-btn">
											&#8634;
										</Button>
									</Col>
								</Row>
							</Col>
						</Row>
						<Row>
							<Col>
								<Table className="table table-striped" responsive="sm" responsive="md" responsive="xs">
									<thead>
										<tr>
											<th scope="col">#</th>
											<th scope="col">
												<Trans>Ticket</Trans>
											</th>
											<th scope="col">
												<Trans>Subject</Trans>
											</th>
											<th scope="col">
												<Trans>Created</Trans>
											</th>
											<th scope="col">
												<Trans>Last Updated</Trans>
											</th>
											<th scope="col">
												<Trans>Status</Trans>
											</th>
											<th scope="col"></th>
										</tr>
									</thead>
									<tbody>
										{data.map((item, index) => {
											return (
												<tr key={index}>
													<td scope="row">{index + 1}</td>
													<td>
														<Link to={`/help-desk/${item.id}`} id={`ticket-btn-${item.id}`}>
															{item.id}
														</Link>
													</td>
													<td>{item.subject}</td>
													<td>{new Date(item.createdAt).toLocaleString()}</td>
													<td> {new Date(item.updatedAt).toLocaleString()}</td>
													<td style={{ textTransform: "capitalize" }}> {item.status}</td>
													<td>
														<Link to={`/help-desk/${item.id}`} id={`ticket-btn-${item.id}`} className="cart-button btn btn-primary">
															<Trans>Details</Trans>
														</Link>
													</td>
												</tr>
											);
										})}
									</tbody>
								</Table>
								
								<div className="text-center w-100 mt-5">
									{viewPaginationBtn ? (
										<Button variant="primary" id="view-more-help-desk-ticket-btn" className="viewMore" onClick={() => setPageNo(pageNo + 1)}>
											<Trans>View More</Trans> <Trans>Tickets</Trans>
										</Button>
									) : (
										<Button variant="secondary" disabled id="view-more-help-desk-ticket-btn" className="viewMore">
											<Trans>All</Trans> <Trans>Tickets</Trans> <Trans>Shown</Trans>
										</Button>
									)}
								</div>
							</Col>
						</Row>
				</div>
			</Container>
		);
	}
};

export default IsLoadingHOC(TicketList, "Loading....");
