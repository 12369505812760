import React, { Fragment } from "react";
import { logo } from "../data/index";
import { Link } from "react-router-dom";
const Logo = () => {
	return (
		<Fragment>
			<Link id="href-logo" to="/" className="navbar-brand">
				<img className="rounded-circle" alt="Brand Logo" src={logo}></img> Brand Logo
			</Link>
		</Fragment>
	);
};
export default Logo;
