import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import IsLoadingHOC from "./../components/IsLoadingHOC";
import { pointHistoryGet } from "../redux/actions";
import { Container } from "react-bootstrap";
import { Trans, useTranslation } from "react-i18next";
import TableReport from "./TableReport";

const PointHistory = (props) => {
	const { t } = useTranslation();
	const setLoading = props.setLoading;
	const [data, setData] = useState([]);
	const [totalPoint, setTotalPoint] = useState(0);

	const columns = [
		{ dataField: "description", text: t("Description") },
		{ dataField: "newDate", text: t("Date") },
		{ dataField: "points", text: t("Points") },
	];

	const getData = async () => {
		props
			.pointHistoryGet()
			.then((response) => {
				if (response["data"]) {
					const resData = response.data.content;					
					let totp = 0;
					resData.forEach((val) => {
						if (val.ledger === "credit") {
							totp += val.points;
						} else {
							totp -= val.points;
						}
					});
					setTotalPoint(totp);
					let rows = resData.map((item, index) => {
						let itemDate = new Date(item.date);
						let newDate = itemDate.toLocaleDateString();
						return { description: item.description, newDate: newDate, points:`${item.ledger === "credit" ? "+" : "-"}${item.points}` };
					});
					setData(rows);
				}
				setLoading(false);
			})
			.catch((ex) => {
				console.log("error in Point List Api", ex);
				setLoading(false);
			});
	};

	useEffect(() => {
		setLoading(true);
		getData();
		//eslint-disable-next-line
	}, []);

	return (
		<Container fluid>
			<div className="container portal-history">
				<div className="point-history-label">
					<h4>
						{" "}
						<Trans>Point History</Trans>{" "}
					</h4>
					<span>
						<b>
							<Trans>Total Point</Trans>
						</b>{" "}
						{totalPoint}
					</span>
				</div>
				<TableReport searchTextboxEnable={false} keyField="eventId" data={data} columns={columns} />
			</div>
		</Container>
	);
};

export default connect(null, { pointHistoryGet })(IsLoadingHOC(PointHistory, "Loading...."));
