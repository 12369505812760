import React, { useEffect, useState } from "react";
import Card from "../Card";
import { rewardsListAction, balanceGetAction } from "../../redux/actions";
import { Button, Container, Col, Row } from "react-bootstrap";
import IsLoadingHOC from "../IsLoadingHOC";
import { Trans, useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { Redirect } from "react-router-dom";

const RewardsList = (props) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const user = useSelector(({ user }) => user);
	const pageLimit = 8;
	const [pageNo, setPageNo] = useState(0);
	const [viewPaginationBtn, setViewPaginationBtn] = useState(false);
	const [data, setData] = useState([]);
	const [rewardsSelectBox, setRewardsSelectBox] = useState("");
	let [rewardListData, setRewardListData] = useState([]);
	const [pageRedirect, setPageRedirect] = useState(false);

	const convertPointsFormat = (points, inActual) => {
		if (inActual) {
			return points.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
		} else {
			if (points >= 1000) {
				points = points % 1000 <= 49 ? `${(points / 1000).toFixed(0)}K` : `${(points / 1000).toFixed(1)}K`;
			}
			return points;
		}
	};
	const [rewardsTypeList, setRewardsTypeList] = useState([]);

	useEffect(() => {
		dispatch(balanceGetAction());
		getRewardsList();
		// eslint-disable-next-line
	}, []);
	const getRewardsList = () => {
		props.setLoading(true);
		setPageNo(0);
		rewardsListAction()
			.then((response) => {
				if (response.data) {
					let rewards = response.data.map((v) => ({ ...v, redeemPointsInK: convertPointsFormat(v.redeemPoints, false) }));
					let rewardsType = [...new Set(rewards.map((v) => v.type))];
					setRewardListData(rewards);
					setRewardsTypeList(rewardsType);
				}
				props.setLoading(false);
			})
			.catch((ex) => {
				console.log("Error In API Call", ex);
				props.setLoading(false);
			});
	};
	const cardClick = async (redirectUrl, redeemPoints) => {
		if (user.pointBalance < redeemPoints) {
			return false;
		}
		setPageRedirect(redirectUrl);
	};
	const showActualPoints = (index, show) => {
		const list = JSON.parse(JSON.stringify(data));
		if (show) {
			list[index].redeemPointsInK = convertPointsFormat(list[index].redeemPoints, true);
		} else {
			if (list[index].redeemPoints >= 1000) {
				list[index].redeemPointsInK = convertPointsFormat(list[index].redeemPoints, false);
			}
		}
		setData(list);
	};
	const getNextPageData = () => {
		let tempRewardList = JSON.parse(JSON.stringify(rewardListData));
		if (rewardsSelectBox !== "") {
			tempRewardList = tempRewardList.filter((v) => v.type === rewardsSelectBox);
		}
		let pageData = tempRewardList.slice(0, pageNo * pageLimit + pageLimit);
		setData(pageData);
		if (pageNo * pageLimit + pageLimit >= tempRewardList.length) {
			setViewPaginationBtn(false);
		} else {
			setViewPaginationBtn(true);
		}
	};
	const filterRewardList = async (e) => {
		setPageNo(0);
		setRewardsSelectBox(e.target.value);
	};
	useEffect(() => {
		getNextPageData();
		// eslint-disable-next-line
	}, [rewardListData, rewardsSelectBox, pageNo]);
	if (pageRedirect) {
		return <Redirect push to={pageRedirect} />;
	} else {
		return (
		<Container fluid>
			<div className="post-login">
				<Container fluid className="rewards-list-container">
					
					<Row>
						<Col>
							<h2>
								<Trans>Rewards</Trans>
							</h2>
						</Col>
					</Row>
					<Row>
						<Col>
							<div className="form-group rewards-category-div">
								<label htmlFor="rewardsCategory" className="mt-2">
									<Trans>Filter</Trans> 
									{/* <Trans>Rewards</Trans> */}
								</label>
								<select className="form-control" id="rewardsCategory" value={rewardsSelectBox} onChange={filterRewardList}>
									<option value="">{`${t("Show")} ${t("ALL")}`}</option>
									{rewardsTypeList.map((v, i) => {
										return (
											<option key={i + "_typeOption"} value={v}>
												{v}
											</option>
										);
									})}
								</select>
							</div>
						</Col>
					</Row>
					<div className="rewards-list-body">
						<Row xs={1} md={2} lg={4} className="card_holder">
							{data.map((item, index) => {
								return (
									<Col key={index}>
										<Card>
											<div
												onMouseOver={(e) => {
													showActualPoints(index, true);
												}}
												onMouseLeave={(e) => {
													showActualPoints(index, false);
												}}
												onClick={(e) => cardClick(`/reward-preview/${item.id}`, item.redeemPoints)}
												className={(user.pointBalance >= item.redeemPoints ? " " : "activity-block-disable ") + "activity-block"}>
												<div className="activity-img-holder">
													<img src={item.image} alt="Avatar" />
												</div>
												<div className="activity-info">
													<div className="points">
														<Trans>Points</Trans>: {item.redeemPointsInK}
													</div>
													<h5>
														{item.itemName}
													</h5>
													<p>
														{item.typeDescription}
													</p>
												</div>
												<div className="row justify-content-center">
													<Button disabled={user.pointBalance >= item.redeemPoints ? "" : true} rewards-item={item.itemId} id={`redeem-btn-${item.id}`} className="cart-button col-10">
														{user.pointBalance >= item.redeemPoints ? t("Redeem Now") : t("Unlock With More Points")}
													</Button>
												</div>
											</div>
										</Card>
									</Col>
								);
							})}
						</Row>
					</div>
					<div className="text-center w-100">
						{viewPaginationBtn ? (
							<Button variant="secondary" id="view-more-earn-pointlist-btn" className="viewMore" onClick={() => setPageNo(pageNo + 1)}>
								<Trans>View More</Trans> <Trans>Rewards</Trans>
							</Button>
						) : (
							<Button variant="secondary" disabled id="view-more-earn-pointlist-btn" className="viewMore">
								<Trans>All</Trans> <Trans>Rewards</Trans> <Trans>Shown</Trans>
							</Button>
						)}
					</div>
				</Container>
			</div>
		</Container>
		);
	}
};

export default IsLoadingHOC(RewardsList, "Loading....");
