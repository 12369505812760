import { SAVE_USER_CONFIG, NOTIFICATION_CENTER_CREATE, NOTIFICATION_CENTER_UPDATE, NOTIFICATION_CENTER_POPUP_CREATE, NOTIFICATION_CENTER_POPUP_UPDATE, LOG_OUT } from "../actions/types";
let initialState = {
	user: {},
	language: "en",
	config: {},
	notificationCenter: [],
	notificationPopupMessageList: [],
};
export default function(state = initialState, action) {
	switch (action.type) {
		case SAVE_USER_CONFIG:
			return { ...state, config: action.payload };
		case NOTIFICATION_CENTER_CREATE:
			return { ...state, notificationCenter: action.payload };
		case NOTIFICATION_CENTER_UPDATE: {
			let oldNotifications = JSON.parse(JSON.stringify(state.notificationCenter));
			let notifications = [];
			if (action.payload.length > 0) {
				let tempNotifications = [];
				for (let i in action.payload) {
					if (oldNotifications.filter((v) => v.uuid === action.payload[i].uuid).length === 0) {
						tempNotifications.push(action.payload[i]);
					}
				}
				notifications = tempNotifications.concat(oldNotifications);
			} else {
				notifications = oldNotifications;
			}
			return { ...state, notificationCenter: notifications };
		}
		case NOTIFICATION_CENTER_POPUP_CREATE:
			return { ...state, notificationPopupMessageList: action.payload };
		case NOTIFICATION_CENTER_POPUP_UPDATE: {
			let oldNotificationPopup = JSON.parse(JSON.stringify(state.notificationPopupMessageList));
			let notificationPopup = [];
			if (action.payload.length > 0) {
				let tempNotifications = [];
				for (let i in action.payload) {
					if (oldNotificationPopup.filter((v) => v.uuid === action.payload[i].uuid).length === 0) {
						tempNotifications.push(action.payload[i]);
					}
				}
				notificationPopup = tempNotifications.concat(oldNotificationPopup);
			} else {
				notificationPopup = oldNotificationPopup;
			}
			return { ...state, notificationPopupMessageList: notificationPopup };
		}
		case LOG_OUT:
			return { ...state, user: {}, language: "en", notificationCenter: [], notificationPopupMessageList: [] };
		default:
			return state;
	}
}
